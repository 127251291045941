import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon, DownloadIcon } from '@toasttab/buffet-pui-icons'
import { RtsFile } from './models/rtsModel'
import { getRunType, getStatus } from './shared/functions'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

type ListProps = {
  rtsFiles: RtsFile[]
  refetch: () => void
}

export const RtsList = ({ rtsFiles, refetch }: ListProps) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { mutateAsync: download } = useMutation({
    mutationFn: async (s3FileName: string) => {
      const response = await fetch(`/rts-generation-job/download`, {
        method: 'POST',
        body: s3FileName
      })

      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }

      // simulate a download
      const url = URL.createObjectURL(await response.blob())
      const a = Object.assign(document.createElement('a'), {
        href: url,
        download: s3FileName.split('/').pop()
      })
      a.click()
      URL.revokeObjectURL(url)
    },
    onSuccess: () => {
      showSuccessSnackBar('RTS file is downloading')
    },
    onError: (error) => {
      showErrorSnackBar('Error downloading RTS file')
      console.error('Error downloading RTS file: ', error)
    }
  })

  return (
    <Table density='condensed-x'>
      <Head>
        <Row>
          <HeadingCell>Status</HeadingCell>
          <HeadingCell>Quarter</HeadingCell>
          <HeadingCell>Year</HeadingCell>
          <HeadingCell>Run Type</HeadingCell>
          <HeadingCell>Username</HeadingCell>
          <HeadingCell>Started At</HeadingCell>
          <HeadingCell>Completed At</HeadingCell>
          <HeadingCell>
            <IconButton
              icon={<AutorenewIcon aria-label='refresh' />}
              onClick={() => refetch()}
            />
          </HeadingCell>
        </Row>
      </Head>
      <Body>
        {rtsFiles.map((rtsFile: RtsFile) => {
          return (
            <Row key={rtsFile.jobId}>
              <Cell>{getStatus(rtsFile.status)}</Cell>
              <Cell>{rtsFile.quarter}</Cell>
              <Cell>{rtsFile.year}</Cell>
              <Cell>{getRunType(rtsFile.runType)}</Cell>
              <Cell>{rtsFile.username}</Cell>
              <Cell>{rtsFile.startedAt}</Cell>
              <Cell>{rtsFile.completedAt}</Cell>
              <Cell>
                {rtsFile.s3FileName && (
                  <IconButton
                    icon={<DownloadIcon aria-label='download' />}
                    onClick={() => download(rtsFile.s3FileName)}
                  />
                )}
              </Cell>
            </Row>
          )
        })}
      </Body>
    </Table>
  )
}
